<template>
  <v-menu bottom left offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <v-card class="text-center">
      <div class="pa-3">
        <v-row v-if="isConnectAllowed || isFleetCommanderAllowed" no-gutters>
          <v-col v-if="isFleetCommanderAllowed">
            <v-btn class="app-btn" text href="https://fleet.usail.es">
              <div>
                <div class="app-icon mb-1">
                  <img src="https://file.usail.es/static/fleet-commander-icon-white.svg" />
                </div>
                <span class="caption">Fleet</span>
              </div>
            </v-btn>
          </v-col>
          <v-col v-if="isConnectAllowed">
            <v-btn class="app-btn" text href="https://connect.usail.es">
              <div>
                <div class="app-icon mb-1">
                  <img style="margin-top: -3px" src="https://file.usail.es/static/connect-icon-white.svg" />
                </div>
                <span class="caption">Connect</span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isProteusAllowed" no-gutters>
          <v-col>
            <v-btn class="app-btn" text href="https://proteus.usail.es">
              <div>
                <div class="app-icon mb-1">
                  <img src="https://file.usail.es/static/proteus-icon-white.svg" />
                </div>
                <span class="caption">Proteus</span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ToolbarApps',
  computed: {
    isConnectAllowed() {
      return this.$usailSecurity.hasRoleInOrganizations(['ROLE_SHIPOWNER', 'ROLE_SHORE_STAFF'])
    },

    isFleetCommanderAllowed() {
      return (
        this.$usailSecurity.hasRoleInOrganizations(['ROLE_SHIPOWNER', 'ROLE_SHORE_STAFF']) ||
        this.$usailSecurity.hasRoleInVessels(['ROLE_CAPTAIN', 'ROLE_FLEET_MANAGER'])
      )
    },

    isProteusAllowed() {
      return (
        this.$usailSecurity.hasReachableRole('ROLE_GOD') ||
        this.$usailSecurity.hasReachableRole('ROLE_STAFF') ||
        this.$usailSecurity.hasReachableRole('ROLE_DEALER')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.app-btn {
  margin: 0;
  width: 80px;
  height: 110px !important;
  img {
    height: 40px;
  }
}

.app-icon {
  background-color: #2196f3;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
  }
}
</style>
