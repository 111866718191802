<template>
  <v-menu v-if="userInfo" offset-y :close-on-content-click="false" :nudge-width="200">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar size="32">
          <img v-if="user && user.image" :src="user.image.contentUrl + '?x-request=html'" :alt="userInfo.name" />
          <img v-else :src="require('@/assets/img/no-avatar.svg')" />
        </v-avatar>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img v-if="user && user.image" :src="user.image.contentUrl + '?x-request=html'" :alt="userInfo.name" />
            <img v-else :src="require('@/assets/img/no-avatar.svg')" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ userInfo.name ? userInfo.name : userInfo.preferred_userame }}</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo.email }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :href="accountHost">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" text @click="$router.push({ name: 'logout' })">Logout</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      userInfo: Object,
      accountHost: process.env.VUE_APP_ACCOUNT_HOST,
      user: null,
      error: null
    }
  },

  created() {
    this.userInfo = this.$usailSecurity.getUser()
    if (this.userInfo) {
      return this.$api
        .fetch('/users/' + this.userInfo.sub)
        .then(response => response.json())
        .then(data => {
          this.user = data
        })
        .catch(e => {
          this.error = e
        })
    }
  }
}
</script>
