<template>
  <v-app-bar
    app
    class="main-toolbar"
    :color="!isStandalone ? 'blue' : 'white'"
    :dark="!isStandalone"
    fixed
    clipped-left
    :tabs="!!$slots['tabs']"
    flat
    style="z-index: 15;"
  >
    <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

    <img src="https://file.usail.es/static/logbook-logo-white.svg" alt="USAIL Logbook" style="max-width: 140px" />

    <v-spacer></v-spacer>

    <slot name="buttons"></slot>

    <ToolbarApps v-if="!isStandalone"></ToolbarApps>
    <ToolbarUser></ToolbarUser>

    <slot slot="extension" name="tabs"></slot>
  </v-app-bar>
</template>

<script>
import ToolbarUser from '@/components/ToolbarUser'
import ToolbarApps from '@/components/ToolbarApps'

export default {
  components: {
    ToolbarUser,
    ToolbarApps
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    prevRoute: {
      type: Object,
      required: false,
      default: null
    }
  },

  computed: {
    isStandalone() {
      return window.matchMedia('(display-mode: standalone)').matches
    }
  },

  methods: {
    toggleDrawer: function() {
      this.$store.commit('drawerSetStatus', !this.$store.state.drawer.status)
    }
  }
}
</script>
